<template>
    
    <article class="bg-fondo-gris p-4 ">

        <Splide :options="valoresSLider" id="slider-libros" class="slider-libros">
            <template v-for="libro in librosConVacios" :key="libro">
                <SplideSlide class="">
                    <router-link class="relative slider-hover" v-if="libro" :to="{ name: 'libro', params: { id: libro._id }}">
                        <img class="w-full relative" :src="libro.image" />
                        <p class="slider-titulo-libro mt-2">
                        {{ cortarName(libro.name) }}
                        </p>
                    </router-link>
                    <span v-else >
                        <router-link to="/qr" >
                            <img class="w-full" src="@/assets/img/agregar.png" />
                            <p class="slider-titulo-libro mt-2">
                            Escanear Libro
                            </p> 
                        </router-link> 
                    </span>
                </SplideSlide>            
            </template>
        </Splide>

    </article>
    
</template>

<script setup>

    import { computed } from 'vue';
    import { useLibrosStore } from '../store/libros' 
    import { storeToRefs } from 'pinia'
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    const valoresSLider = {
        rewind: true,
        perPage: 5,
        perMove: 1,
        arrows : true,
        gap: '1rem',
        breakpoints: {
            1400: { 
                arrows : false
            },
            1200: { 
                perPage: 4,
            }
        },
    }

    const librosStore        = useLibrosStore()
    const { libros }         = storeToRefs(librosStore)

    const librosConVacios = computed(() => {
        const totalLibros = 5;
        const librosExistente = libros.value.length;
        const librosVacios = totalLibros - librosExistente;

        const librosCompletos = [...libros.value]; 

        for (let i = 0; i < librosVacios; i++) {
            librosCompletos.push(null);
        }

        return librosCompletos;
    });

    const cortarName = (str) => {
        if (str.length > 18) {
         return str.slice(0, 18) + '...'
        }
        return str
    }
         
</script>
