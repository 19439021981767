<script setup>

    import { storeToRefs } from 'pinia'
    import { useRoute } from 'vue-router'   
    import { useLibrosStore } from '../store/libros' 

    const librosStore = useLibrosStore()   

    const { params } = useRoute()
    const idDestacado = params.idDestacado

    librosStore.getDestacadaById(idDestacado)

    librosStore.notificate( idDestacado, 'ContenidoDestacado' )

    const { destacadaById } =  storeToRefs(librosStore)    
   

    console.log(destacadaById);

    const getFormattedDate = (fecha) => {
        const date = new Date(fecha)
        const options = { year: 'numeric', month: 'short', day: 'numeric' }
        return date.toLocaleDateString('es-ES', options)
    }

</script>


<template>

        <section v-if="destacadaById.libro" class="interior-post">

            <div class="autor-post py-3 px-5">                
                <router-link :to="{ name: 'autor' , params: {id: destacadaById.libro.autor._id}}">
                    <div class="w-1/2 flex items-center">
                        <img 
                            class="w-11 h-11 object-cover rounded-full mr-1.5"
                            :src="destacadaById.libro.autor.image"
                        />
                        <div class="text-oscuro">
                            <p class="text-[16px] font-semibold">{{ destacadaById.libro.autor.name }} {{ destacadaById.libro.autor.surname }}</p>
                            <p class="text-[14px]">{{ getFormattedDate(destacadaById.created_at) }}</p>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="cabecera-destacado relative">
                <div v-if="destacadaById.type === 'text'">
                    <img class="w-full img-max-interior object-cover" :src="destacadaById.libro.autor.background" />
                </div>
                <div v-if="destacadaById.type === 'video'">
                    <div style="position: relative; padding-top: 56.25%;"> 
                        <iframe  :src="destacadaById.file"
                            loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" 
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true">
                        </iframe>
                    </div>
                </div>
                <div class="flex w-full absolute top-0 py-3 px-5">
                    <div class="w-1/2 flex items-center">
                    </div>
                    <div class="w-1/2 " >
                        <img class="rigth-0 mr-0 ml-auto" src="@/assets/img/favorito.png" />
                    </div>
                </div>
            </div>

            <div class="autor-post py-4 px-5 bg-fondo-gris">
                <router-link :to="{ name: 'libro' , params: {id: destacadaById.libro._id}}">
                    <div class="w-1/2 flex items-center">                    
                        <img class="w-11 h-11 object-cover rounded-full mr-1.5" :src="destacadaById.libro.image" />
                        <div class="text-oscuro">
                            <p class="text-[16px] font-bold uppercase ">{{ destacadaById.libro.name }}</p>
                            <p class="text-[14px] font-bold text-amarillo uppercase ">{{ destacadaById.libro.autor.name }} {{ destacadaById.libro.autor.surname }}</p>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="cuerpo-destacado pt-4 pb-7 px-5 text-left">
                <div class="titulo tipo-texto" >
                    <span>{{ destacadaById.title }}</span> | <span>{{ destacadaById.libro.name }}</span>
                </div>
                <div class="descripcion" v-html="(destacadaById.description)">
                </div>
            </div>



        </section>

        <hr class="separador-libros"/>

        




</template>


<style scoped>
    

</style>
