<template>
    <div class="libros mx-auto">
      <MenuTop></MenuTop>
      <TitularCabecera color="rojo" titular="Autores" v-model="buscaAutor" :buscador="true"></TitularCabecera>
      <ListaAutores :buscaAutor="buscaAutor"></ListaAutores>
      <MenuFooter></MenuFooter>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import MenuTop from '@/components/MenuTop.vue'
  import MenuFooter from '@/components/MenuFooter.vue'
  import ListaAutores from '@/components/ListaAutores.vue'
  import TitularCabecera from '@/components/TitularCabecera.vue'
  
  const buscaAutor = ref('')
  </script>
  