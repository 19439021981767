export default {
    es: {
        recovery: 'Si tu usuario existe, recibirás un correo electrónico con las indicaciones necesarias para restaurar la contraseña',
        change: 'Tu contraseña se ha cambiado correctamente',
        activate: 'Tu cuenta se ha activado correctamente',
        create: 'Te hemos enviado un correo electrónico. Haz click en el enlace para activar tu cuenta.',
    },
    en: {

    }
  }