//import { fetchWrapper } from './fetch-wrapper';

export const isValidTime = function() {
    // cogemos fecha de entrada y expires_in
    if ( localStorage.getItem('user') == null ) {
        return false;
    }
    const UserData = JSON.parse(localStorage.getItem('user'));
    console.log(UserData);
    if ( UserData == null ) return false;
    // compatibilidad versiones anteriores
    if ( typeof UserData.date_start == 'undefined' ) {
        return false;
    }
    const DateIn        = UserData.date_start;
    const ExpiresIn     = UserData.expires_in;
    // cogemos fecha actual 
    const FechaActual   = Math.round( new Date().getTime() / 1000 );
    /////////////////////////////////////
    return ( FechaActual < (DateIn + ExpiresIn) );
}  