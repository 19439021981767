<template>
    <div>

       
    </div>
</template>

<script setup>
    //import { ref } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useActivateStore } from '@/store/activate.store' 
    import { useRoute } from 'vue-router';  
    import router from '@/router'
    /*import router from '@/router'*/

    const activacion    = useActivateStore();
    const { sepuede }   = storeToRefs(activacion);
    const route         = useRoute();
    activacion.comprobarDatos( route.query ).then(()=>{
        if ( sepuede.value == true ) {
            router.push('/?ok=1&module=users&sub=activate');
        }
    });
</script>