import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import messages from './assets/js/language.js'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
import VueJsTour from '@globalhive/vuejs-tour';
import '@globalhive/vuejs-tour/dist/style.css';

//import { useLibrosStore } from '@/store/libros' 


import router  from '@/router'

import App from './App.vue'

import QrReader from 'vue3-qr-reader';

/* css custom */ 
import './assets/tailwind.css'
import './assets/custom.css'



const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)

const i18n = createI18n({
    locale: 'es',
    messages,
    legacy: false
})

app.use(pinia) 
app.use(router)
app.use(QrReader)
app.use(i18n)
app.use(VueSplide)
app.use(Skeleton)
app.use(VueJsTour)

app.config.isCustomElement = tag => tag === 'app';
app.mount('#app')
app.config.globalProperties.$attrs = { lang: 'es' };