<template>
    <div></div>
</template>

<script setup>
console.log('procesar-view');
    function getParam(  ) {
        /*const urlParams = new URL( window.location ).searchParams;
        return urlParams.get( param );*/
        const currentURL = new URL(window.location.href);
        const codeQR = currentURL.pathname.replace('/procesar-qr/', '');
        return codeQR;
    }
    import { storeToRefs } from 'pinia';
    import { useQRStore } from '@/store/qr.store' 
    import router from '@/router'

    const qr                            = useQRStore();
    const { codeQr, response }          = storeToRefs( qr );
    console.log(response);

    const code          = getParam();
    if ( code != '' ) {
        codeQr.value            = code;
    }
    if ( codeQr.value != false ) {
        router.push('/'); 
    }
</script>