<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        destacadas: []
    })
    
    const getFormattedDate = (fecha) => {
        const date = new Date(fecha)
        const options = { year: 'numeric', month: 'short', day: 'numeric' }
        return date.toLocaleDateString('es-ES', options)
    }

    const cortarDescripcion = (str) => {
        if (str.length > 400) {
        return str.slice(0, 400) + '...</p>'
        }
        return str
    }

</script>


<template>
    <article class="vista-pc">
        <div class="cont-dest-homepc mt-14">

            <div v-for="destacada in props.destacadas" :key="destacada._id">
                
                <router-link :to="{ name: 'post' , params: { idDestacado: destacada._id }}">  
                    
                    <div class="flex mb-10 hover-entrada">
                        <div class="w-1/2 cont-img-hover">
                            <img class="w-full h-96 object-cover " :src="destacada.libro.autor.background" />
                            <p class="caja-oscura"></p>
                        </div>
                        <div class="w-1/2 relative">
                            <div class="flex w-full  top-0 py-3 px-5">
                                <router-link :to="{ name: 'autor' , params: {id: destacada.libro.autor._id}}">
                                    <div class="flex items-center">
                                        <img class="w-11 h-11 object-cover rounded-full mr-1.5" :src="destacada.libro.autor.image" />
                                        <div class>
                                            <p class="nombre-rounded font-semibold">{{ destacada.libro.autor.name }} {{ destacada.libro.autor.surname }}</p>
                                            <p class="fecha-rounded">{{ getFormattedDate(destacada.created_at) }}</p>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="w-1/2 " >
                                    <img class="rigth-0 mr-0 ml-auto" src="@/assets/img/favorito.png" />
                                </div>
                            </div>
                            <div class="cuerpo-destacado pt-4 pb-7 px-5 text-left">
                                <div class="titulo tipo-texto" >
                                    <span>{{ destacada.title }}</span> | <span>{{ destacada.libro.name }}</span>
                                </div>
                                <p class="descripcion" v-html="cortarDescripcion(destacada.description)"></p>
                                <button>Ver más →</button>
                            </div>
                            
                        </div>
                    </div> 

                </router-link>

            </div>  

        </div>
        
    </article>

    

    <article class="ultima-entrada vista-movil">

        <div v-for="destacada in props.destacadas" :key="destacada._id">

            <router-link :to="{ name: 'post' , params: { idDestacado: destacada._id }}">  

                <div class="cabecera-destacado relative">
                    <img class="w-full max-h-[160px] object-cover" :src="destacada.libro.autor.background" />
                    <div class="flex w-full absolute top-0 py-3 px-5">
                        <div class="w-1/2 flex items-center">
                            <img class="w-11 h-11 object-cover rounded-full mr-1.5" :src="destacada.libro.autor.image" />
                            <div class="text-claro">
                                <p class="text-[16px] font-semibold">{{ destacada.libro.autor.name }} {{ destacada.libro.autor.surname }}</p>
                                <p class="text-[14px]">{{ getFormattedDate(destacada.created_at) }}</p>
                            </div>
                        </div>
                        <div class="w-1/2 " >
                            <img class="rigth-0 mr-0 ml-auto" src="@/assets/img/favorito.png" />
                        </div>
                    </div>
                </div>
                <div class="cuerpo-destacado pt-4 pb-7 px-5 text-left">
                    <div class="titulo tipo-texto" >
                        <span>{{ destacada.title }}</span> | <span>{{ destacada.libro.name }}</span>
                    </div>
                    <p class="descripcion" v-html="cortarDescripcion(destacada.description)">
                        
                    </p>
                </div>

            </router-link>

        </div>      

        
    </article>
</template>

