<template>

    <div class="buscador azul">
        <input type="text"  />
    </div>

    <article class="cont-notificaciones p-6  ">

        <div class="noti">
            <div class="img-noti">
                <img class="object-cover" src="@/assets/img/rigoberta.png" />
            </div>
            <div class="logo-img">
                <img class="object-cover" src="@/assets/img/icono-noti.png" />
            </div>
            <div class="texto-noti">
                <p class="titular-noti"><strong>Rigoberta Bandini</strong> ha subido un nuevo vídeo de <span>Vértigo</span></p>
                <p class="fecha-noti">14 nov 2023</p>
            </div>
        </div>

        <div class="noti">
            <div class="img-noti">
                <img class="object-cover" src="@/assets/img/rigoberta.png" />
            </div>
            <div class="logo-img">
                <img class="object-cover" src="@/assets/img/icono-noti.png" />
            </div>
            <div class="texto-noti">
                <p class="titular-noti"><strong>Rigoberta Bandini</strong> ha subido un nuevo vídeo de <span>Vértigo</span></p>
                <p class="fecha-noti">14 nov 2023</p>
            </div>
        </div>

        <div class="noti">
            <div class="img-noti">
                <img class="object-cover" src="@/assets/img/rigoberta.png" />
            </div>
            <div class="logo-img">
                <img class="object-cover" src="@/assets/img/icono-noti.png" />
            </div>
            <div class="texto-noti">
                <p class="titular-noti"><strong>Rigoberta Bandini</strong> ha subido un nuevo vídeo de <span>Vértigo</span></p>
                <p class="fecha-noti">14 nov 2023</p>
            </div>
        </div>

        <div class="noti">
            <div class="img-noti">
                <img class="object-cover" src="@/assets/img/rigoberta.png" />
            </div>
            <div class="logo-img">
                <img class="object-cover" src="@/assets/img/icono-noti.png" />
            </div>
            <div class="texto-noti">
                <p class="titular-noti"><strong>Rigoberta Bandini</strong> ha subido un nuevo vídeo de <span>Vértigo</span></p>
                <p class="fecha-noti">14 nov 2023</p>
            </div>
        </div>

        <div class="noti">
            <div class="img-noti">
                <img class="object-cover" src="@/assets/img/rigoberta.png" />
            </div>
            <div class="logo-img">
                <img class="object-cover" src="@/assets/img/icono-noti.png" />
            </div>
            <div class="texto-noti">
                <p class="titular-noti"><strong>Rigoberta Bandini</strong> ha subido un nuevo vídeo de <span>Vértigo</span></p>
                <p class="fecha-noti">14 nov 2023</p>
            </div>
        </div>
       

        
    </article>

</template>


<script>


</script>


<style scoped>

    

</style>