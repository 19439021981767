import { defineStore } from 'pinia'
import { fetchWrapperNotLogin } from '@/helpers';
const listaPaises = process.env.VUE_APP_API_BASE + '/localization/list?page=1&perPage=250';
const listaProb = process.env.VUE_APP_API_BASE + '/localization/list/states/';
const listaCiudades = process.env.VUE_APP_API_BASE + '/localization/list/cities/';
const insertUser = process.env.VUE_APP_API_BASE + '/user/add';

export const useRegistroStore = defineStore('registro',{
    state: () => ({
        paises: [],
        provincias: [],
        ciudades: [],
        mensaje_insert: [],
        registrado:false,
        fails:[]
    }),
    actions: {
        async cargarPaises() {
            try {
                const response = await fetchWrapperNotLogin.get(listaPaises);
                this.paises = response.map((pais) => pais.name)
            } catch (error) {
                console.error(error);
            }
        },
        async cargarProvincias(idPais) {
            try {
                const response = await fetchWrapperNotLogin.get(listaProb + idPais );
                this.provincias = response.map((prov) => prov.name);
            } catch (error) {
                console.error(error);
            }
        },
        async cargarCiudades(idPais,idProb) {
            try {
                const response = await fetchWrapperNotLogin.get(listaCiudades + idPais + "/" + idProb + '?page=1&perPage=250' );
                this.ciudades = response.map((ciu) => ciu.name);
            } catch (error) {
                console.error(error);
            }
        },
        async insertarUser(datos){

            let response = ""
            response = await fetchWrapperNotLogin.post(insertUser, datos);
            console.log(response)
            this.mensaje_insert = response.message;                
            this.registrado = response.ok;
            if( response.ok==0){
                this.fails = response.message.data;
            }else{
                this.fails = [];
            }
        }

    },
     
})
