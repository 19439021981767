<template>
    <div class="login mx-auto">

        <div class="logincont">
            <section class="logologin">
                <img src="@/assets/img/logologin.png" class="mx-auto" alt="Mas que lectura" />
            </section>
            <section class="cont-form">
                <form @submit.prevent="onSubmit" class="form-login">
                    <input class="" type="email" v-model="form.email" placeholder="Email" pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})" />
                    <div class="cont-form" style="text-align: center;margin: 10px 0px">
                        <small v-if="errorShow">{{errorText}}</small>
                    </div>                 
                    <input class="inputbutton" type="submit" value="Solicitar">
                    <div class="request success" v-if="ok">{{ message }}</div>   
                    <div class="request error" v-if="!ok">{{ message }}</div> 
                </form>
                <div class="link-form recu-pass">
                    <router-link to="/">Volver al login</router-link>
                </div>
                <div class="link-form recu-pass">
                    <router-link to="/recuperar-contrasena">¿Olvidó su contraseña?</router-link>                    
                </div>  
                
            </section>
        </div>

    </div>

</template>

<script setup>
    import { ref } from 'vue';
    import { storeToRefs } from 'pinia';
    /*import { useAuthStore } from '../store/auth.store.js';*/
    import { useRecuperarStore } from '@/store/recuperar.store'    
    import router from '@/router'

    const cambiarContrasena =  useRecuperarStore();
    
    const form = ref({
        ok: false,
        message: '',
        email: ''
    });     
    const { ok, message } = storeToRefs(cambiarContrasena);
    function onSubmit() {        
        //ok.value = false
        const { email } = form.value;
        const datos = { email:email }
        cambiarContrasena.solicitarCambioContrasena( datos ).then(()=>{
            console.log(useRecuperarStore.ok);
            if ( ok ) {
                router.push('/?ok=1&module=password&sub=recovery');
            }
        });
        /*errorShow.value = false
        const authStore = useAuthStore();
        const { email, password } = form.value;
        const LibrosStore = useLibrosStore()
        authStore.login(email, password).then(() => {
          errorShow.value = false
           LibrosStore.fetchLibros().then(()=>{
            LibrosStore.fetchRelatedBooks()
            router.push('/')
           })    
        })
        .catch((error) => { 
            console.log(error)
            errorShow.value = true
            errorText.value = error
        }); */  

    }

</script>


<style scoped> 

    


  

</style>


  

   
