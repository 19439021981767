<template>
    <div class="libros ancho-variable mx-auto">
        <MenuTop />
        <TitularCabecera color="azul" titular="Contenido"></TitularCabecera>
        <SliderLibros />
        <UltimasEntradas :destacadas="destacatasHome" />
        <MenuFooter />
    </div>
</template>
  
<script>
import MenuTop from "@/components/MenuTop.vue";
import MenuFooter from "@/components/MenuFooter.vue";
import SliderLibros from "@/components/SliderLibros.vue";
import UltimasEntradas from "@/components/UltimasEntradas.vue";
import TitularCabecera from "@/components/TitularCabecera.vue";
import { useAuthStore } from "../store/auth.store";
import { useLibrosStore } from "../store/libros";
import { isValidTime } from '@/helpers';
import router from '@/router';
import { ref, watch } from "vue";
import { storeToRefs } from 'pinia'

const scrolledToBottom = ref(false);

export default {
    components: {
        MenuTop,
        MenuFooter,
        SliderLibros,
        UltimasEntradas,
        TitularCabecera
    },
    setup() {
        const librosStore = useLibrosStore();
        const { destacatasHome } = storeToRefs(librosStore);

        const EsValido = isValidTime();
        if (!EsValido) {
            useAuthStore().logout()
            router.push('/')
        }

        librosStore.fetchLibros();
        librosStore.fetchRelatedBooks()

        // Llenamos autores
        librosStore.getAutores()

        // Destacados home
        librosStore.getDestacadasHome();

        return { page: 1, destacatasHome: destacatasHome, scrolledToBottom: false };


    },
    mounted() {
        this.scrollToTop()
        this.scroll()

    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollEvt)
    },
    methods: {
        scroll() {
            const { getDestacadasHome } = useLibrosStore()
            window.addEventListener('scroll', this.scrollEvt)
            watch(scrolledToBottom, (newValue, oldValue) => {
                if (newValue !== oldValue && newValue == true) {
                    this.page += 1;
                    getDestacadasHome(this.page);
                }
            });
        },
        scrollEvt() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
            scrolledToBottom.value = bottomOfWindow;
            console.log(scrolledToBottom.value);
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
};
</script>