<template>
     
    <article class="slide-interior">

        <div class="fondo-curvo flex sin-fondo"  :style="{ backgroundImage: `url(${require('@/assets/img/libro2.jpg')})` }">
            <img class="" src="@/assets/img/img-transparente.png" />
        </div>

        <div class="interior-circulo">
            <img class="imagen-redonda" :src="autor.image" />
            <p class="titular-circulo"> {{ autor.name }} {{ autor.surname }}</p>
            <!--<p class="subtitulo-circulo">{{ autor.name }} {{ autor.surname }}</p>-->
            <p class="texto-circulo" v-html="autor.description"></p>
        </div>

    </article>

    <hr class="separador-libros"/>


    
    <article class="scroll-libros overflow-x-auto cont-libros p-4 relative degradado-slider libros-autor" >
     
        
        <Splide :options="valoresSLider" class="">
            <template v-for="abook in autorBooks" :key="abook._id">
                <SplideSlide class="" >
                    <div v-if="abook.user === false" >
                        <div class="portada-libro">
                            <div class="capa-descubre">
                                <img class="w-8" src="@/assets/img/candado.png" />
                            </div>
                            <img :src="abook.image" />                
                        </div>                
                        <div class="cont-nombre-titulo my-1.5">
                            <p class="nombre-libro">{{ abook.name }}</p>
                        </div>
                    </div>
                    <div v-else >
                        <router-link :to="{ name: 'libro' , params: {id: abook._id}}">
                            <div class="portada-libro">                        
                                <img :src="abook.image" />                
                            </div>
                            
                            <div class="cont-nombre-titulo my-1.5">
                                <p class="nombre-libro">{{ abook.name }}</p>
                            </div>
                        </router-link> 
                    </div>
                </SplideSlide>
            </template>
        </Splide>

        

    </article>
        
    <hr class="mt-4 separador-libros"/>
    <UltimasEntradasInteriorAutor :destacadas="destacatasAutor"></UltimasEntradasInteriorAutor>

</template>

<script>
import { useAuthStore } from "../store/auth.store";
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'   
import { useLibrosStore } from '../store/libros' 
import { ref, watch } from "vue";
import { isValidTime } from '@/helpers';
import UltimasEntradasInteriorAutor from '@/components/UltimasEntradasInteriorAutor.vue'; 
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import router from "@/router";



const scrolledToBottom = ref(false);

export default {
    components: {
        UltimasEntradasInteriorAutor
    },
    setup() {
        const librosStore   = useLibrosStore()
        const { params }    = useRoute()
        const autorId       = params.id   

        const valoresSLider = {
            rewind: true,
            perPage: 5,
            perMove: 1,
            arrows : true,
            gap: '1rem',
            breakpoints: {
                1400: { 
                    arrows : false
                },
                1200: { 
                    perPage: 4,
                }
            },
        }

        const EsValido = isValidTime();
        if ( !EsValido ) {
            useAuthStore().logout()
            router.push('/')
        }

        librosStore.fetchAutorBooks(autorId)     
        librosStore.getDestacadasAutor(autorId)

        const { autor, destacatasAutor, autorBooks } = storeToRefs(librosStore)
        console.log(autorBooks)
        return { page: 1, autor: autor, destacatasAutor: destacatasAutor, scrolledToBottom: false, autorBooks: autorBooks, valoresSLider, Splide, SplideSlide  };
    },
    mounted() {
        this.scrollToTop()
        this.scroll()
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollEvt)
    },
    methods: {
        scroll() {
            const { getDestacadasAutor }     = useLibrosStore()
            const { params }                 = useRoute()
            const autorPId                   = params.id
            window.addEventListener('scroll', this.scrollEvt)
            watch(scrolledToBottom, (newValue, oldValue) => {
                if (newValue !== oldValue && newValue == true) {
                    this.page += 1;
                    getDestacadasAutor( autorPId, this.page );
                }
            });
        },
        scrollEvt() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
            scrolledToBottom.value = bottomOfWindow;
            console.log(scrolledToBottom.value);
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
};
</script> 
