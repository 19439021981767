<template>

    <article class="flex pt-6 px-5 cont-libros">
        
        <div v-for="libro in filteredLibros" :key="libro._id" class="cont-libro">
            <router-link :to="{ name: 'libro' , params: {id: libro._id}}">
                <div class="portada-libro">
                    <img :src="libro.image" />                
                </div>
                <div class="cont-nombre-titulo my-1.5">
                    <p class="nombre-libro">{{ libro.name }}</p>
                    <p class="autor-libro">{{ libro.autor.name }} {{ libro.autor.surname }}</p>
                </div>
            </router-link>
        </div>

    </article>

    <hr class="separador-libros"/>

    <article class=" pt-6 px-5 cont-libros descubre">

        <div class="descubre">
            Descubre nuevas lecturas
        </div>
        
        <Splide :options="valoresSLider" class="">
            <template v-for="rbook in relatedBooks" :key="rbook._id">
                <SplideSlide class="" >
                    <div class="portada-libro">
                        <div class="capa-descubre">
                            <img class="w-5" src="@/assets/img/candado.png" />
                        </div>
                        <img :src="rbook.image" />                
                    </div>
                    <div class="cont-nombre-titulo my-1.5">
                        <p class="nombre-libro">{{ cortarName(rbook.name) }}</p>
                        <p class="autor-libro">{{ rbook.autor.name }} {{ rbook.autor.surname }}</p>
                    </div>
                </SplideSlide>  
            </template>                      
        </Splide>            

    </article>

</template>

<script setup>

    import { computed, defineProps} from 'vue'
    import { useLibrosStore } from '../store/libros' 
    import { useAuthStore } from "../store/auth.store";
    import { isValidTime } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/vue-splide/css';
    import router from '@/router';

    const prop = defineProps({
        buscaLibro: String
    });

    const valoresSLider = {
        rewind: true,
        perPage: 5,
        perMove: 1,
        arrows : true,
        gap: '1rem',
        breakpoints: {
            1400: { 
                arrows : false
            },
            1200: { 
                perPage: 4,
            }
        },
    }

    const EsValido = isValidTime();
    if ( !EsValido ) {
        useAuthStore().logout()
        router.push('/')
    }

    const { libros, relatedBooks } = useLibrosStore()
    
    const  filteredLibros = computed(() => {
        if (!prop.buscaLibro) {
            return libros
        } else {
            const lowerSearchTerm = prop.buscaLibro.toLowerCase()
            return libros.filter(libro => 
                libro.name.toLowerCase().includes(lowerSearchTerm) || 
                libro.autor.name.toLowerCase().includes(lowerSearchTerm) ||
                libro.autor.surname.toLowerCase().includes(lowerSearchTerm)
            )
        }
     })    
 
     const cortarName = (str) => {
        if (str.length > 15) {
         return str.slice(0, 15) + '...'
        }
        return str
    }  

</script>







