<template>
    <nav class="menu-top sticky top-0 z-50 bg-claro w-full p-4 ">
        <div v-if="!isHome" class="absolute top-1/2 left-4 translate-y-n50 cursor-pointer flex">
            <p @click="$router.go(-1)" class="mr-2"><img src="@/assets/img/flecha-volver.png" alt="volver" /> </p><p>Volver</p>
        </div>
        <div class="logo-home">
            <div class="vista-pc menu-iz">
                <ul>
                    <li><router-link to="/home" class="micontenido">Mi Contenido exclusivo</router-link></li>
                    <li><router-link to="/biblioteca" class="mibiblioteca">Mi Biblioteca</router-link></li>
                </ul>                
            </div>
            <router-link to="/">
                <!--src="@/assets/img/logo.png"-->
                <img src="@/assets/img/logologin.png" class="mx-auto" alt="Mas que lectura" />
            </router-link>
            <div class="vista-pc menu-der">
                <ul>
                    <li><router-link to="/autores" class="misautores">Mis Autores</router-link></li>
                    <li><router-link to="/qr">Escanear Libro</router-link></li>
                    <li><a href="#" @click="showLoginDialog = true" >Salir</a></li>
                </ul>
            </div>
        </div>
        <div class="first:[&>a]:ml-0 absolute top-1/2 right-4 translate-y-n50">
            <img class="hamburguer vista-movil" src="@/assets/img/menu-mvil.png" @click="toggleMenu" />
           <!--  <a href="#" @click="isOpen = !isOpen"><img class="hamburguer vista-movil" src="@/assets/img/menu.png" alt="Mis autores" /></a>
           <a href="#" @click="showLoginDialog = true"><img src="@/assets/img/salir-pc.png" class="hamburguer vista-pc"/></a>
            -->
        </div>

        <transition name="slide">
            <div v-if="showMenu" class="menu" >
                <div class="cerrar-menu"><p @click="toggleMenu">X</p></div>                
                <ul>
                    <li><router-link to="/home">Mi Contenido</router-link></li>
                    <li><router-link to="/biblioteca">Mi Biblioteca</router-link></li>
                    <li><router-link to="/autores">Mis Autores</router-link></li>
                    <li><router-link to="/qr">Escanear Libro</router-link></li>
                    <li><a href="#" @click="showLoginDialog = true" @click.self="toggleMenu">Salir</a></li>           
                </ul>
                <div class="rrss">
                    <div>Síguenos en nuestras RRSS</div>
                    <hr>
                    <ul class="lista-rrss">
                        <li><a href="https://www.instagram.com/masquelecturamql/" target="_blank"><img src="@/assets/img/instagram.png" alt="RRSS" /></a></li>
                        <li><a href="https://twitter.com/MasQueLectura" target="_blank"><img src="@/assets/img/twitter.png" alt="RRSS" /></a></li>
                        <li><a href="https://www.linkedin.com/in/m%C3%A1squelectura-mql-801887283/" target="_blank"><img src="@/assets/img/linkedin.png" alt="RRSS" /></a></li>
                        <li><a href="https://www.facebook.com/profile.php?id=100094571325649" target="_blank"><img src="@/assets/img/facebook.png" alt="RRSS" /></a></li>
                    </ul>
                </div>                
            </div>
        </transition>    
    
    </nav>
    
    <!--<div v-if="isOpen" class="sub-menu flex justify-between">
        <div class="w-full bg-azul fuentes-menu">
            <router-link to="/home">Contenido</router-link>
        </div>
        <div class="w-full bg-amarillo fuentes-menu">
            <router-link to="/biblioteca">Biblioteca</router-link>
        </div>
        <div class="w-full bg-rojo fuentes-menu">
            <router-link to="/autores">Autores</router-link>
        </div>
    </div>-->
    
    <div v-if="mostrar" class="fixed z-50 top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div class="caja-ejemplar">
            <img src="@/assets/img/logo.png" class="mx-auto mt-2 mb-5" alt="Mas que lectura" />            
            <p v-if="response.ok" class="mensaje">{{response.message}}</p>
            <p v-else class="mensaje rojo">{{response.message.message}}</p>
            <div class="mt-4">
                <button class="blue" @click="mostrar = false">Cerrar</button>
            </div>
        </div>
    </div>

    <div v-if="showLoginDialog" class="fixed z-50 top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div class="caja-unlogin">
            <img src="@/assets/img/logo.png" class="mx-auto mt-2 mb-5" alt="Mas que lectura" />
            <p>¿Está seguro de que quiere salir?</p>
            <div class="mt-4">
                <button class="blue" @click="showLoginDialog = false">Cancelar</button>
                <router-link to="/">
                    <button class="red" @click="authStore.logout()">Salir</button>
                </router-link>
            </div>
        </div>
    </div>

    
</template>


<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useQRStore } from '@/store/qr.store'
import { useAuthStore } from '../store/auth.store.js';

const QrStore                               = useQRStore()
const { anadido, response }                 = storeToRefs( QrStore )
const mostrar                               = ref(false);
if ( anadido.value == true ) {
    // Mostramos mensaje  
    mostrar.value     = ( response != null  );
    anadido.value     = false;
}
const authStore = useAuthStore();
/* True por defecto visible - false oculto*/
//const isOpen = ref(true)
const showMenu = ref(false);

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const route = useRoute()

// Computed property que verifica si la ruta actual es la página de inicio
const isHome = computed(() => route.path === '/home')
const showLoginDialog = ref(false)
</script>