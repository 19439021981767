<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRegistroStore } from '../store/registro.store'
import router from '@/router'
import Select2 from 'vue3-select2-component';

const storeRegistro = useRegistroStore()
const { paises, provincias, ciudades, registrado } = storeToRefs(storeRegistro);
storeRegistro.cargarPaises()
const showPassword = ref(false);
const activeField = ref('password');
import verPassImg from '@/assets/img/ver.png';
import esconderImg from '@/assets/img/esconder.png';
const showpassimg = ref(verPassImg);
const hidepassimg = ref(esconderImg);

const form = ref({
    name: '',
    surname: '',
    phone: '',
    country: '',
    state: '',
    city: '',
    email: '',
    password: '',
    password_confirmation: ''
});

function onCountrySelect() {
    storeRegistro.cargarProvincias(form.value.country)
}

function onStateChange() {
    storeRegistro.cargarCiudades(form.value.country, form.value.state)
}

function togglePasswordVisibility(field) {
    if (activeField.value === field) {
        showPassword.value = !showPassword.value;
    } else {
        showPassword.value = true;
        activeField.value = field;
    }
}

//Errores y cositas
const errorShow = ref(false);
const errorText = ref('');
const errors = ref({});
const fallos      = ref({});

function onSubmit() {
    errorShow.value = false
    errors.value = {};

    // Validación del campos
    if (!form.value.name) {
        errors.value.name = "El campo Nombre es obligatorio";
    }
    if (!form.value.surname) {
        errors.value.surname = "El campo Apellidos es obligatorio";
    }
    if (!form.value.phone) {
        errors.value.phone = "El campo Teléfono es obligatorio";
    }
    if (!form.value.country) {
        errors.value.country = "El campo País es obligatorio";
    }
    if (!form.value.state) {
        errors.value.state = "El campo Provincia es obligatorio";
    }
    if (!form.value.city) {
        errors.value.city = "El campo Ciudad es obligatorio";
    }
    if (!form.value.email) {
        errors.value.email = "El campo Email es obligatorio";
    }
    if (!form.value.password) {
        errors.value.password = "El campo Contraseña es obligatorio";
    }
    if (!form.value.password_confirmation) {
        errors.value.password_confirmation = "El campo Repite Contraseña es obligatorio";
    }
    // Validar que las contraseñas coincidan
    if (form.value.password !== form.value.password_confirmation) {
        errors.value.password_confirmation = "Las contraseñas no coinciden";
    }
    //validar que la contraseña tiene Mayus Minus 8 caracteres
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(form.value.password)) {
        errors.value.password = "La contraseña deberá tener mínimo 1 Mayúscula, 1 Número y una longitud de 8 caracteres";
    }

    if (Object.keys(errors.value).length > 0) {
        errorShow.value = true;
        errorText.value = "Hay errores en el formulario";
        return;
    }

    const { name, surname, phone, country, state, city, email, password, password_confirmation } = form.value;
    const datos = { name: name, surname: surname, phone: phone, country: country, state: state, city: city, email: email, password: password, password_confirmation: password_confirmation }

    storeRegistro.insertarUser(datos).then(() => {
        if (registrado.value == true) {
            router.push('/?ok=1&module=users&sub=create');
        }
    }).catch((error) => {
        let errorData   = JSON.parse(error);
        fallos.value    = errorData.data;
        console.log(fallos.value);
    });


}
</script>


<template>
    <div class="login mx-auto">
    
        <div class="logincont">
            <section class="logologin">
                <img src="@/assets/img/logologin.png" class="mx-auto" alt="Mas que lectura" />
            </section>
    
            <section class="cont-form">
                <form @submit.prevent="onSubmit" class="form-login">
                    <input class="" :class="{ 'border-error': errors.name }" type="text" v-model="form.name" placeholder="Nombre" />
                    <div v-if="errors.name" class="cont-error"><small>{{ errors.name }}</small></div>
    
                    <input class="" :class="{ 'border-error': errors.surname }" type="text" v-model="form.surname" placeholder="Apellidos" />
                    <div v-if="errors.surname" class="cont-error"><small>{{ errors.surname }}</small></div>
    
                    <input class="" :class="{ 'border-error': errors.phone }" type="tel" v-model="form.phone" placeholder="Teléfono" pattern="^[6789]{1}\d{8}$" title="El campo del teléfono deberá de tener nueve dígitos y deberán de empezar por 6, 7, 8 o
                                            9" />
                    <div v-if="errors.phone" class="cont-error"><small>{{ errors.phone }}</small></div>
    
                    <Select2 v-model="form.country" :options="paises" :settings="{ placeholder: 'País' }" @select="onCountrySelect" :class="{ 'border-error': errors.country }">
                    </Select2>
                    <div v-if="errors.country" class="cont-error"><small>{{ errors.country }}</small></div>
    
                    <Select2 v-model="form.state" :options="provincias" :settings="{ placeholder: 'Provincia' }" @select="onStateChange" :class="{ 'border-error': errors.state }">
                    </Select2>
                    <div v-if="errors.state" class="cont-error"><small>{{ errors.state }}</small></div>
    
                    <Select2 v-model="form.city" :options="ciudades" :settings="{ placeholder: 'Ciudad' }" :class="{ 'border-error': errors.city }">
                        <option value="" disabled>Ciudad</option>
                        <option v-for="ciudad in ciudades" :key="ciudad.id" :value="ciudad.name">{{ ciudad.name }}</option>
                    </Select2>
                    <div v-if="errors.city" class="cont-error"><small>{{ errors.city }}</small></div>
    
                    <input class="" :class="{ 'border-error': errors.email || fallos.email  }" type="email" v-model="form.email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                    <div v-if="errors.email" class="cont-error"><small>{{ errors.email }}</small></div>
                    <div v-if="fallos.email" class="cont-error"><small>{{ fallos.email[0] }}</small></div>
    
                    <div class="cont-pass">
                        <input class="" :class="{ 'border-error': errors.password }" :type="activeField === 'password' && showPassword ? 'text' : 'password'" v-model="form.password" placeholder="Contraseña">
                        <img class="ver-pass" :src="activeField === 'password' && showPassword ? hidepassimg : showpassimg" @click="togglePasswordVisibility('password')">
                    </div>
    
                    <div v-if="errors.password" class="cont-error"><small>{{ errors.password }}</small></div>
    
                    <div class="cont-pass">
                        <input class="" :class="{ 'border-error': errors.password_confirmation }" :type="activeField === 'confirmation' && showPassword ? 'text' : 'password'" v-model="form.password_confirmation" placeholder="Repite Contraseña">
                        <img class="ver-pass" :src="activeField === 'confirmation' && showPassword ? hidepassimg : showpassimg" @click="togglePasswordVisibility('confirmation')">
                    </div>
                    <div v-if="errors.password_confirmation" class="cont-error"><small>{{ errors.password_confirmation }}</small></div>
    
                    <!--<div class="cont-form" style="text-align: center;margin: 10px 0px">
                                                            <small v-if="errorShow">{{errorText}}</small>
                                                        </div>  -->
                    <input class="inputbutton" type="submit" value="Crear cuenta">
                </form>
                <div class="link-form recu-pass">
                    <router-link to="/">Volver al login</router-link>
                    <router-link to="/">¿Olvidó su contraseña?</router-link>
                </div>
    
            </section>
        </div>
    
    </div>
</template>



<style scoped>

</style>


  

   
