import { defineStore } from 'pinia'
import { fetchWrapperNotLogin } from '@/helpers';
const RecuperarLink = process.env.VUE_APP_API_BASE + '/checkPasswordToken'; 
const CambiarContrasenaLink = process.env.VUE_APP_API_BASE + '/changePassword'; 

export const useCambiarStore = defineStore('recovery_1',{
    state: () => ({ sepuede: false, password: '', password_confirmation: '', errors: {}, ok: false }),
    actions: {
        async comprobarDatos( datos ) {
            this.sepuede = false
            const response  = await fetchWrapperNotLogin.post(RecuperarLink, datos);
            if ( response.message == 'ok' )
                this.sepuede = true
        },
        async cambiarContrasena( datos ) {
                const response  = await fetchWrapperNotLogin.post(CambiarContrasenaLink, datos);
                this.ok         = ( response.message == 'ok' );
        } 
    },
     
})