<template>
    <div class="libros mx-auto">
        <MenuTop></MenuTop>
        <TitularCabecera color="amarillo" titular="Biblioteca" v-model="buscaLibro" :buscador="true"></TitularCabecera>
        <LibrosBiblioteca :buscaLibro="buscaLibro"></LibrosBiblioteca> 
        <MenuFooter></MenuFooter>
    </div>
</template>
  
<script setup>
import { ref } from 'vue'
import MenuTop from '@/components/MenuTop.vue';
import MenuFooter from '@/components/MenuFooter.vue';
import LibrosBiblioteca from '@/components/LibrosBiblioteca.vue';
import TitularCabecera from "@/components/TitularCabecera.vue";

const buscaLibro = ref('')
</script>



