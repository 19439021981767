<template>
    
    <div class="p-12" v-if="!isQR">

    </div>

    <div class="w-full fixed bottom-0 overflow-hidden z-50 vista-movil">
        <nav class="menu-footer p-4 flex justify-around items-end z-50 relative menu-footer">
            <div><router-link to="/">
                <img class="volver-home" v-if="isHome" src="@/assets/img/inicio.png" />
                <img class="volver-home" v-else src="@/assets/img/inicio.png" />
            </router-link></div>
            <!--<div v-if="demo"><router-link to="/" class="opacity-0"><img src="@/assets/img/buscar.png" /></router-link></div>-->
            <div><router-link to="/qr"><div @click="showQrScanner(true)"><img class="agregar-libro" src="@/assets/img/agregar-libro.png" /></div></router-link></div>
            <!--<div  v-if="demo"><router-link to="/" class="opacity-0"><img src="@/assets/img/favoritos.png" /></router-link></div>-->
            <div><router-link to=""  @click="showLoginDialog = true"><div ><img src="@/assets/img/salir.png" class="salir-footer"/></div></router-link></div>
        </nav>
        <div v-if="showLoginDialog" class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div class="caja-unlogin">
                <img src="@/assets/img/logo.png" class="mx-auto mt-2 mb-5" alt="Mas que lectura" />
                <p>¿Está seguro de que quiere salir?</p>
                <div class="mt-4">
                    <button class="blue" @click="showLoginDialog = false">Cancelar</button>
                    <router-link to="/">
                        <button class="red" @click="authStore.logout()">Salir</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>   
    
    <router-link to="/qr">
    <div class="vista-pc leerQRPc">
        <div><div @click="showQrScanner(true)"><img src="@/assets/img/agregar-libro.png" /></div></div>
            
    </div></router-link>

    <div v-if="showLoginDialog" class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div class="caja-unlogin">
            <img src="@/assets/img/logo.png" class="mx-auto mt-2 mb-5" alt="Mas que lectura" />
            <p>¿Está seguro de que quiere salir?</p>
            <div class="mt-4">
                <button class="blue" @click="showLoginDialog = false">Cancelar</button>
                <router-link to="/">
                    <button class="red" @click="authStore.logout()">Salir</button>
                </router-link>
            </div>
        </div>
    </div>
    
</template>



<script setup>

import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '../store/auth.store.js';

const authStore = useAuthStore();

const route = useRoute()
const isQR = computed(() => route.path === '/qr')
const isHome = computed(() => route.path === '/home')
const showLoginDialog = ref(false)

</script>


