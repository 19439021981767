<template>
    <div class="sub-menu flex">
      <div :class="`w-full bg-${color} fuentes-menu`">
        <p>{{ titular }}</p>
        <div v-if="buscador" class="buscador cont-libros" :class="color">
          <input type="text" v-model="searchTerm" @input="emitSearch" />
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits, ref } from 'vue'
  
  const props = defineProps({
    color: String,
    titular: String,
    modelValue: String,
    buscador: Boolean  // Agregamos la prop buscador de tipo Boolean
  })
  
  const emit = defineEmits(['update:modelValue'])
  
  const searchTerm = ref(props.modelValue)
  
  const emitSearch = () => {
    emit('update:modelValue', searchTerm.value)
  }
  </script>
  