<template>
    <article class="slide-interior">
    
    
        <!--<div class="fondo-curvo flex"  :style="{'background-image': 'url(' + libro.background + ')'}">-->
        <div class="fondo-curvo flex sin-fondo" :style="{ backgroundImage: `url(${require('@/assets/img/libro2.jpg')})` }">
            <img class="vista-movil" :src="libro.image" />
            <div clasS="vista-pc texto-slider">
                <p class="titular-circulo"> {{ libro.name }}</p>
                <p class="subtitulo-circulo">{{ libro.autor.name }} {{ libro.autor.surname }}</p>
            </div>
            <!--<img class="vista-pc" src="@/assets/img/img-transparente.png" />-->
        </div>
        <div class="interior-circulo">
            <router-link :to="{ name: 'autor' , params: {id: libro.autor._id}}">
                <img class="imagen-redonda" :src="libro.autor.image" />
            </router-link>
            <div class="vista-movil">
                <p class="titular-circulo"> {{ libro.name }}</p>
                <p class="subtitulo-circulo">{{ libro.autor.name }} {{ libro.autor.surname }}</p>
                <p class="texto-circulo" v-html="libro.description"></p>
            </div>
            <div class="vista-pc cont-new-libro">
                <div class="cont-caratula-libro">
                    <img class="" :src="libro.image" />
                </div>
                <div class="cont-texto-libro">
                    <p class="titular-circulo"> Sinopsis </p>
                    <p class="texto-circulo" v-html="libro.description"></p>
                </div>
    
            </div>
    
        </div>
    
    
    
        <!--{{ libro }}-->
    
    </article>
    
    
    
    <hr class="separador-libros" />
    <UltimasEntradasInteriorLibro :destacadas="destacatasLibro"></UltimasEntradasInteriorLibro>
</template>

<script>
import { useAuthStore } from "../store/auth.store";
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useLibrosStore } from '../store/libros'
import { ref, watch } from "vue";
import { isValidTime } from '@/helpers';
import UltimasEntradasInteriorLibro from '@/components/UltimasEntradasInteriorLibro.vue';
import router from '@/router';

const scrolledToBottom = ref(false);

export default {
    components: {
        UltimasEntradasInteriorLibro
    },
    setup() {
        const librosStore = useLibrosStore()
        const { destacatasLibro } = storeToRefs(librosStore)
        const { params } = useRoute()
        const libroId = params.id
        
        const EsValido = isValidTime();
        if ( !EsValido ) {
            useAuthStore().logout()
            router.push('/')
        }

        librosStore.getDestacadasLibro(libroId)
        console.log(destacatasLibro)
        const libro = librosStore.libros.find(obj => obj._id === libroId);
        return { page: 1, libro: libro, destacatasLibro: destacatasLibro, scrolledToBottom: false };
    },
    mounted() {
        this.scrollToTop()
        this.scroll()
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollEvt)
    },
    methods: {
        scroll() {
            const { getDestacadasLibro } = useLibrosStore()
            const { params } = useRoute()
            const libroPId = params.id
            window.addEventListener('scroll', this.scrollEvt)
            watch(scrolledToBottom, (newValue, oldValue) => {
                if (newValue !== oldValue && newValue == true) {
                    this.page += 1;
                    getDestacadasLibro(libroPId, this.page);
                }
            });
        },
        scrollEvt() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
            scrolledToBottom.value = bottomOfWindow;
            console.log(scrolledToBottom.value);
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
};
</script>

