<template>
  <div class="overlay-qr" :style="{ height: overlayHeight + 'px'}">
    
    <div >

      <h1 :style="{ marginTop: paddingTop + 'px' }">¡Escanea el código<br> de tu nuevo libro!</h1>

      <div class="stream">

        <qr-stream @decode="onDecode" class="mb">      
        </qr-stream>

      </div>
      
      <div v-if="qrStore.response">
        <div v-if="qrStore.response.ok === true">
          <div class="mensaje verde">{{ qrStore.response.message }}</div>            
        </div>
        <div v-else>
          <div class="mensaje rojo">{{ qrStore.response.message.message }}</div>   
        </div>
      </div>
      
      <div v-if="qrStore.response">
        <div class="botonera">
          <div v-if="qrStore.response.ok === true">
              <button @click="$router.push('/biblioteca')">Ir a Biblioteca</button>            
          </div>
          <div>
              {{ qrStore.code }}
              <a v-if="!qrStore.response.ok" :href="'mailto:' + email">
                 <button>Ayuda</button>
              </a>
              <button @click="$router.go(-1)">Volver</button>  
          </div>                               
        </div>
      </div>

             

    </div>

 </div>
</template>


<script setup>
    import { ref, onMounted, onBeforeUnmount  } from 'vue';
    import { useQRStore } from '../store/qr.store';
    import { useLibrosStore } from '../store/libros' 
    import { useAuthStore } from "../store/auth.store";
    import { isValidTime } from '@/helpers';
    import router from '@/router';

    const EsValido = isValidTime();
    if ( !EsValido ) {
        useAuthStore().logout()
        router.push('/')
    }

    const { fetchLibros,fetchRelatedBooks } = useLibrosStore()
    const email = "ayuda@masquelectura.com"

    const overlayHeight = ref(0);
    const paddingTop = ref(0);

    const qrStore = useQRStore();
    qrStore.response = false
    const onDecode = (codeQr) => {
        codeQr = codeQr.substring(codeQr.lastIndexOf('/') + 1)
        console.log(codeQr)
        qrStore.fetchQr(codeQr).then(()=>{
          fetchLibros()
          fetchRelatedBooks()
        })
    };

    const updateOverlayHeight = () => {
      // Obtener las capas que deseas restar
      const subMenu = document.querySelector(".sub-menu");
      const menuTop = document.querySelector(".menu-top");

      // Calcular el alto de las capas que se restarán
      const subMenuHeight = subMenu ? subMenu.offsetHeight : 0;
      const menuTopHeight = menuTop ? menuTop.offsetHeight : 0;

      // Calcular el alto de la capa overlay-qr
      overlayHeight.value = window.innerHeight - subMenuHeight - menuTopHeight;
      paddingTop.value = 15;
    };

    onMounted(() => {
      // Calcular el alto inicial
      updateOverlayHeight();

      // Escuchar el evento resize de la ventana
      window.addEventListener("resize", updateOverlayHeight);
    });

    onBeforeUnmount(() => {
      // Eliminar el event listener cuando el componente se desmonte
      window.removeEventListener("resize", updateOverlayHeight);
    });
    
</script>