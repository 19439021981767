import { defineStore } from 'pinia'
import { fetchWrapperNotLogin } from '@/helpers';
const ActivateLink = process.env.VUE_APP_API_BASE + '/activate'; 
//const ActivateLink = 'http://localhost:8000/api/activate';
export const useActivateStore = defineStore('activate',{
    state: () => ({ sepuede: false }),
    actions: {
        async comprobarDatos( datos ) {
            const response  = await fetchWrapperNotLogin.post(ActivateLink, datos);
            this.sepuede    = ( response.message == 'ok' );
        }
    },
     
})