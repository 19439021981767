<template>

    <article class="flex pt-6 px-5 cont-libros">

        <div v-for="autor in filteredAutores" :key="autor._id" class="cont-libro">
            <router-link :to="{ name: 'autor' , params: {id: autor._id}}">
                <div class="portada-libro">
                    <img :src="autor.image" />                
                </div>
                <div class="cont-nombre-titulo my-1.5">
                    <p class="nombre-libro">{{ autor.name }} {{ autor.surname }}</p>
                </div>
            </router-link>
        </div>        

    </article>
    
    <hr class="separador-libros"/>

</template>

<script setup>
    
    import { computed, defineProps } from 'vue'
    import { useLibrosStore } from '../store/libros' 
    import { useAuthStore } from "../store/auth.store";
    import { isValidTime } from '@/helpers';
    import router from '@/router';

    const prop = defineProps({
        buscaAutor: String
    });

    const EsValido = isValidTime();
    if ( !EsValido ) {
        useAuthStore().logout()
        router.push('/')
    }

    const { getAutores, autores} = useLibrosStore();
    
    getAutores()

    autores.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            })
    
    const filteredAutores = computed(() => {

       
        console.log(autores)

        if (!prop.buscaAutor) {
            return autores
        } else {
            const lowerSearchTerm = prop.buscaAutor.toLowerCase()
            return autores.filter(autor => 
                autor.name.toLowerCase().includes(lowerSearchTerm) ||
                autor.surname.toLowerCase().includes(lowerSearchTerm)
            )
        }

    })

</script>
