import { defineStore } from 'pinia'
import { fetchWrapperNotLogin } from '@/helpers';
const RecuperarLink = process.env.VUE_APP_API_BASE + '/forgetPassword';

export const useRecuperarStore = defineStore('recovery',{
    state: () => ({ ok: false, message: ''  }),
    actions: {
        async solicitarCambioContrasena( datos ) {
                const response          = await fetchWrapperNotLogin.post(RecuperarLink, datos);
                this.ok                 = response.ok;
        }
    },
     
})
