import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers';
const baseUrl = process.env.VUE_APP_API_BASE + '/content/serve';
const destacadasHomeUrl = process.env.VUE_APP_API_BASE + '/content/highlights';
const destacadasLibroUrl = process.env.VUE_APP_API_BASE + '/content/libro/highlights/';
const destacadasAutoUrl = process.env.VUE_APP_API_BASE + '/content/autor/highlights/';
const destacadaByIdUrl = process.env.VUE_APP_API_BASE + '/content/highlight/';
const relatedUrl = process.env.VUE_APP_API_BASE + '/related/books';
const autorBooksUrl = process.env.VUE_APP_API_BASE + '/autor/get_libros/';
const notificationUrl = process.env.VUE_APP_API_BASE + '/notificaciones/destroy/';

export const useLibrosStore = defineStore('libros',{
    state: () => ({
      libros: [],
      autores: [],
      autor: [],
      relatedBooks: [],
      autorBooks: [],
      destacatasHome: [],
      destacatasLibro: [],
      destacatasAutor: [],
      destacadaById: {}
    }),
    persist: {
      storage: sessionStorage,
    },
    actions: {
      async fetchLibros() {   
        const libros = await fetchWrapper.get( baseUrl ,''); 
        this.libros = libros.data.libros; 
      },
      async getDestacadasHome( page = 1 ) {
        if ( page == 1 ) this.destacatasHome = [];
        /////////////////
        const destacatasHome = await fetchWrapper.get( destacadasHomeUrl + "?page=" + page ); 
        //this.destacatasHome.push(destacatasHome.data); 
        for ( var i = 0; i < destacatasHome.data.length; i++ ) {
            this.destacatasHome.push( destacatasHome.data[ i ] ); 
        }        
      },
      async getDestacadasLibro( id, page = 1 ){
        if ( page == 1 ) this.destacatasLibro = []
        ////////////////////
        const destacatasLibro = await fetchWrapper.get( destacadasLibroUrl + id + "?page=" + page ); 
        for ( var i = 0; i < destacatasLibro.data.length; i++ ) {
            this.destacatasLibro.push( destacatasLibro.data[ i ] ); 
        }
        console.log(this.destacatasLibro);
      },
      async getDestacadasAutor( id, page = 1 ){
        if ( page == 1 ) this.destacatasAutor = []
        ///////////////////////////
        const destacatasAutor = await fetchWrapper.get( destacadasAutoUrl + id + "?page=" + page); 
        for ( var i = 0; i < destacatasAutor.data.length; i++ ) {
            this.destacatasAutor.push( destacatasAutor.data[ i ] ); 
        }
      },
      async getDestacadaById(id){
        //this.destacadaById = {}
        const destacadaById = await fetchWrapper.get( destacadaByIdUrl + id ); 
        this.destacadaById = destacadaById.data; 
        console.log(destacadaById)
        console.log(this.destacadaById)
      },
      async notificate( id, type ){
        //this.destacadaById = {}
        var resultUrl          = notificationUrl + id + '/' + type;
        await fetchWrapper.post( resultUrl );
      },
      async getAutores() {  
        this.libros.forEach(libro => {
          if (!this.autores.some((a) => a._id === libro.autor._id)) {
            this.autores.push(libro.autor)
        }     
        });
      },
      async fetchRelatedBooks() {  

        if(this.relatedBooks.length==0){
          this.relatedBooks = await fetchWrapper.get( relatedUrl ,'');  
        }
      },
      async fetchAutorBooks(autorId) {  
        const autor = this.autores.find(obj => obj._id === autorId)
        this.autor = autor
        console.log(this.autor)
        console.log(this.autores)
        fetchWrapper.get( autorBooksUrl + autorId )
        .then((books) => {
          this.autorBooks = books.data
        })
        .catch(error => this.autorBooks = { error })

      }
    }
  })