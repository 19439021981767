import { defineStore } from 'pinia';

import router from '@/router'
import { fetchWrapper } from '@/helpers';

const baseUrl               = process.env.VUE_APP_API_BASE + '/login';
//const baseUrlLogout         = process.env.VUE_APP_API_BASE + '/logout';
const baseUrlMe             = process.env.VUE_APP_API_BASE + '/me';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse( localStorage.getItem('user') ),
        userData: {},
        returnUrl: null
    }),
    actions: {
        async login(email, password) {
            try {
                const user      = await fetchWrapper.post(`${baseUrl}`, { email, password });
                // date in
                user.date_start = Math.round( new Date().getTime() / 1000 );
                // update pinia state
                this.user = user;
                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                // redirect to previous url or default to home page           
            } catch ( ex ) {
                console.log(ex);
            }
            
        },
        async me() {
            const user = await fetchWrapper.get(`${baseUrlMe}`);
            // update pinia state
            return user.data;         
        },
        async logout() {
            //await fetchWrapper.get(`${baseUrlLogout}`);
            this.user = null;
            localStorage.removeItem('user');
            sessionStorage.removeItem('libros');
            router.push('/');
        }
    }
});
