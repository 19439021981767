<template>
    <router-view/> 
    <VTour ref="tour" :steps="steps" :buttonLabels="buttonLabels" highlight/>

    <div v-if="showWelcomeModal" class="welcome-modal">
        
        <div class="welcome-modal-content">

            <div class="cerrar-modal click-cerrar" @click="cerrarModal">X</div>

            <img src="@/assets/img/logologin.png" class="mx-auto logo-modal" alt="Mas que lectura" />
            
            <div class="texto-modal">
                <h1>Bienvenido a Más que lectura</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet 
                    cursus metus, <b>ac molestie orci</b>. Vivamus suscipit egestas lorem, nec 
                    elementum justo scelerisque non. Vivamus accumsan a enim sit amet suscipit. 
                    Orci varius natoque penatibus <b>et magnis dis parturient montes</b>, nascetur 
                    ridiculus mus. Nulla eget fermentum felis. Nam finibus sed metus 
                    non consequat. <b>Maecenas urna turpis</b>, auctor vitae feugiat id, efficitur vel est.</p>
            </div>

            <div class="botones-modal">
                <button class="welcome-modal-button" @click="startTour">Iniciar tutorial</button>
                <button class="welcome-modal-button click-cerrar" @click="cerrarModal">Saltar tutorial</button>
            </div>
            
        </div>  

    </div>

</template>

<script setup>
    import { ref, onMounted } from 'vue';
    const showWelcomeModal = ref(false);
    const tour = ref(null);

    const html = document.documentElement 
    html.setAttribute('lang', 'es')

    const steps = [
        {
            target: '.micontenido',  
            content: '<div>hola<br><b>test</b></div>'
        },
        {
            target: '.mibiblioteca',
            content: 'Paso 2'
        },
        {
            target: '.misautores',
            content: 'Paso 3'
        }
    ]

    const buttonLabels = { 
        next: "Siguiente", 
        prev: "Anterior", 
        finish: "Terminar", 
        skip: "Salir"
    }

    // Verificar si el modal de bienvenida ya se ha mostrado anteriormente
    const checkWelcomeModal = () => {
        showWelcomeModal.value = false;
        /*if (!localStorage.getItem('welcomeModalShown')) {
            showWelcomeModal.value = true;
        }*/
    }

    // Función para cerrar el modal de bienvenida y abrir el tour
    const startTour = () => {
        showWelcomeModal.value = false;
        //localStorage.setItem('welcomeModalShown', 'true');
        tour.value.startTour();
    };

    const cerrarModal = () => {
        showWelcomeModal.value = false;
        //localStorage.setItem('welcomeModalShown', 'true');
    };
        

    onMounted(() => {
        checkWelcomeModal();
        //tour.value.startTour();
    })


</script>
